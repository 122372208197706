/**
 * 查询当前窗口 URL 的参数
 * @param {String} name
 */
export function getQueryString(name) {
  return decodeURIComponent(
    (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || ["", ""])[1]
      .replace(/\+/g, "%20")
  ) || null
}
