<template>
    <div>
      <ul >
        <!-- 思路 三个不同的类 -->
        <li v-for="v in list" :key="v.name">
          <div class="wrap" v-if="syntheticJudge(v.status) === 0">
            <div class="round-box"><span class="round"></span></div>
            <span class="title">{{v.name}}</span>
          </div> 
          <div class="wrap" v-else-if="syntheticJudge(v.status) === 1" >
            <div  class="right-bg right-bg-wihte bg-move"></div>
            <span class="title bold">{{v.name}}</span>
          </div>
          <div class="wrap" v-else-if="syntheticJudge(v.status) === 2">
            <div  class="right-bg"><span class="check-style-equal-width"></span></div>
            <span class="title">{{v.name}}</span>
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
import "animate.css"
import { ref, toRefs, onMounted } from 'vue'
export default {
    props: {
      questionList: {
        type: Array
      },
      next: {
        type: Number
      }
    },
    data() {
      return {}
    },
    setup(props, context) {
      const { questionList, next } = toRefs(props)
      console.log(next.value, props.next)
      let list = ref([])
      list.value = questionList.value.map(v => {
        return {name: v, status: "undo"}
      })
      onMounted(() => {
        // 这里拿不到next的值，但是能拿到props.next
       
        const time = 800
        const next = props.next-1
        console.log("console.log(next, next.value)",next, time)
        if (next === 0) {
          setTimeout(() => {
            list.value[next].status = 'doing'
          }, 0)
          setTimeout(() => {
            context.emit("middleClose", true)
          }, time+200)
        } else {
          list.value.forEach((v, i) => {
            if (i <= next-2) {
              list.value[i].status = "doned"
            }
          })
          setTimeout(() => {
            list.value[next-1].status = 'doing'
          }, 0)
          setTimeout(() => {
            list.value[next-1].status = 'doned'
          }, time)
          setTimeout(() => {
            list.value[next].status = 'doing'
          }, time*2)
          setTimeout(() => {
            context.emit("middleClose", true)
          }, time*3+200)
        }
      })
      return {
        list
      }
    },
    methods: {
      // 综合判断 1即将要开始做；2已经做完；0还未做到的维度
      syntheticJudge(status) {
        if (status === 'doing') {
          return 1
        } else if (status === 'doned') {
          return 2
        } else {
          return 0
        }
      }
    }
}
</script>

<style scoped lang="less">
@keyframes move {
  0% {
    width: 1px;
    height: 1px;
  }
  100% {
    width: 30px;
    height: 30px;
  }
}
ul {
    padding-top: 62px;
    li {
    font-size: 18px;
    .wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 36px;
    }
    .round-box {
        width: 30px;
        height: 30px;
        margin-right: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
            display: inline-block;
            width: 7.5px;
            height: 7.5px;
            border-radius: 50%;
            background: #333;
        }
    }
    .title {
      font-size: 18px;
      line-height: 36px;
      display: inline-block;
      width: 100px;
      text-align: center;
    }
    .bold {
        font-size: 25px;
        font-weight: bold;
        line-height: 36px;
    }
    .right-bg  {
        width: 30px;
        height: 30px;
        margin-right: 22px;
        background: #99F2B2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.bg-move {
          animation: move 1s;
        }
        .right {
          color: #333;
        }
    }
    }
    .check-style-equal-width {
      width: 6px;
      height: 12px;
      border-radius: 2px;
      border-color: #333;
      border-style: solid;
      border-width: 0 4px 4px 0;  
      overflow: hidden;
      transform: rotate(45deg);
    }
}
</style>
