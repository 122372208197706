<template>
    <div class="container">
        <img v-if="!token" src="@/assets/imgs/loading.gif" class="loading">
        <img v-else class="begin-img" :src="backimg" @click="beginTest">
        <div class="btnContent" @click="beginTest">{{showText}}</div>
        <div class="modal" v-if="showPhone" >
            <div class="form">
                <img class="form-img" src="@/assets/imgs/form-bg.png">
                <img class="close" src="@/assets/imgs/close.png" @click="showPhone=false">
                <div class="input-wrap">
                    <img src="@/assets/imgs/phone.png" alt="">
                    <input type="number" placeholder="请输入手机号" v-model="phoneNumber">
                </div>
                <button class="submit" @click="submit">提交</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setToken } from '@/utils/auth'
import { Toast } from 'vant'
import {getQueryString} from "@/utils/utils";

export default {
    components: {
        [Toast.name]: Toast
    },
    data() {
        return {
            backimg:'https://xajk.oss-cn-hangzhou.aliyuncs.com/static/newTest.png',
            showText:'开始测评',
            showPhone: false,
            phoneNumber: "",
            enterpriseId: "",
            appid: "",
            tid: "",
            authType: "",
            uid: "",
            token: "",
            needPhone: 1
        }
    },
    mounted() {
        const enterpriseId = getQueryString('enterprise_id'),
            tid = getQueryString('tid'),
            orderId = getQueryString('order_id'),
            authType = getQueryString('auth_type'),
            channel = getQueryString('channel'),
            type = getQueryString('type'),
            clearFlag = getQueryString('clearFlag')

        if(clearFlag){
            console.log("clearAll........");
            this.clearAll();
        }
        if (enterpriseId) {
            this.enterpriseId = enterpriseId
            localStorage.setItem('enterpriseId', enterpriseId)
        }
        if (tid) {
            this.tid = tid
            localStorage.setItem('tid', tid)
            if(tid == 11){
                this.backimg = 'https://xajk.oss-cn-hangzhou.aliyuncs.com/static/back_img240725.png'
                this.showText = '接受回访'
            }
        }
        if (orderId) {
            localStorage.setItem('orderId', orderId)
        }
        if (authType) {
            this.authType = authType
            localStorage.setItem('authType', authType)
        }
        if (channel) {
            sessionStorage.setItem('channel', channel)
        }
        if (type) {
            sessionStorage.setItem('type', type)
        }
        this.appid = this.$route.query.appid
        this.initCode()
    },
    methods: {
        clearAll() {
            localStorage.clear();
            sessionStorage.clear();
        },
        beginTest() {
            if (this.needPhone) {
                this.showPhone = true
            } else {
                this.$router.push("/test")
            }
        },
        // 提交手机号
        submit() {
            const reg = new RegExp('^1[3|4|5|6|7|8|9][0-9]{9}$')
            const bool = reg.test(this.phoneNumber)
            if (!bool) {
                Toast.fail('请输入正确的手机号码')
                return
            }
            this.$api.phoneOrder({
                data: {
                    phone: this.phoneNumber,
                    tid: localStorage.getItem('tid')
                }
            }).then(() => {
                this.showPhone = false
                this.$router.push("/test")
            }).catch(err => {
                console.log("err", err)
                if (err.code && err.code === 100001) {
                    this._reLogin()
                } else if (err.code === -1000) {{
                    Toast.fail(err.message)
                }}
            })
        },
        // 初始化
        async initCode() {
            const token = localStorage.getItem('token')
            console.log(0, token)
            if (token) {
                // 校验是否过期
                console.log(1)
                try {
                    await this._checkLogin()
                } catch (error) {
                    console.log(3, error)
                }
                await this._bindChannel()
                await this._needPhone()
                console.log(5)

                //进入测评页面，如果答题完成，默认进入结果页面
                const tid = localStorage.getItem("tid")
                const type = sessionStorage.getItem("type") || "try"
                this.$api.assessmentInfo(type, tid).then(res => {
                    console.info("res",res)
                    if (+res.data.result.finish === 1) {
                        this.$router.push("/resultDefault")
                    }
                    console.log(this.questionList)
                    }).catch()
                return
            }
            if (this.$route.query.uid) {
                this.uid = this.$route.query.uid
                this.userLogin()
            } else {
                this._reLogin()
            }
        },
        // 绑定渠道
        async _bindChannel() {
            if (!sessionStorage.getItem('channel')) return
            await this.$api.bindChannel({
                data: {
                    channel: sessionStorage.getItem('channel')
                }
            }).then(() => {
                console.log("bindChannel")
            }).catch()
        },
        // 校验token
        async _checkLogin() {
            console.log(2)
            await this.$api.checkLogin().then(() => {
                // this.$router.push("/test")
                console.log(2.2)
                this.token = localStorage.getItem('token')
            }).catch((err) => {
                console.log(2.3, err)
                this._reLogin()
            })
        },
        async _needPhone() {
            console.log("_needPhone")
            const tid = localStorage.getItem("tid")
            const type = sessionStorage.getItem("type") || "try"
            console.log(4, tid, type)

          await this.$api.needPhone(tid, type).then(res => {
            this.needPhone = +res.data.need_phone
          }).catch()
        },
        // 重新登录
        _reLogin() {
            let api = "",
                domain = ""
            if (process.env.NODE_ENV === "production") {
                api = "https://evalapi.xajkzg.com"
                domain = "https://eval.xajkzg.com/#/"
            } else {
                api = "http://evalapi.dat.xajkzg.com"
                domain = "http://eval.dat.xajkzg.com/#/"
            }
            window.location.href = `${api}/v1/user/web-auth?auth_type=${this.authType}&tid=124&enterprise_id=${this.enterpriseId}&appid=${this.appid}&callback=${encodeURIComponent(domain)}`
        },
        // 获取token
        async userLogin() {
            const id = localStorage.getItem('enterpriseId')
            this.$api.Login(this.uid, id).then(async (res) => {
                const token = res.data.token
                this.token = token
                localStorage.setItem('token', token)
                setToken(token)
                await this._bindChannel()
                await this._needPhone()
            }).catch()
        }
    }
}
</script>

<style scoped lang="less">
.container {
  position: relative;
  height: 100vh;
  .begin-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 使用 cover 值保持图片比例并填充整个容器 */
  }
  .loading {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    .form {
      width: 279px;
      height: 250px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .form-img {
        width: 100%;
        height: 100%;
      }
      .close {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
      .input-wrap {
        width: 214px;
        height: 30px;
        border-radius: 3px;
        overflow: hidden;
        background: #fff;
        position: absolute;
        bottom: 78px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        img {
          position: absolute;
          top: 8px;
          left: 8.5px;
          width: 15px;
          height: 15px;
        }
        input {
          border: none;
          width: 100%;
          height: 100%;
          font-size: 13px;
          color: rgba(51, 51, 51, .3);
          font-size: 13px;
          padding-left: 35px;
          box-sizing: border-box;
          outline: none;
        }
      }
      .submit {
        position: absolute;
        width: 135px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
        font-size: 13px;
        font-weight: bold;
        border-radius: 16.5px;
        background: #fff;
      }
    }

  }
  .btnContent {
    position: absolute;
    width: 300px;
    height: 53.33px;
    bottom: 72px;
    left: 37px;
    background: url("https://xajk.oss-cn-hangzhou.aliyuncs.com/wwxapi/2022-07-29/a6e8993282cac2ac04a750af6fda1fc2.png");
    background-size: 100%;
    line-height: 53.33px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #905901;
  }
}

</style>
