<template>
    <div>
        <Top :current="locationIndex" :total="questionList.length" @back="handleBack"/>
        <Middle v-if="showMiddle" :questionList="dimensionList" :next="dimensionNext" @middleClose="handleclose"/>
        <div v-else-if="!showMiddle && questionList.length" :class="['question-wrap animate__animated', moveClass ? 'animate__fadeInRight' : '']">
            <div class="content" v-for="(v, idx) in questionList" :key="idx">
                <div v-if="_show(3, v, idx)" class="filling">
                    <div class="label">{{v.title}}</div>
                    <div class="tips">{{tips}}</div>
                    <div class="fill" v-if="v.id != 350">
                        <div class="fill-input">
                            <input :type="v.blankType===1?'number':'text'" class="input" v-model="inputValue" placeholder="请输入">
                            <div class="right-bg" @click="showNext(v, idx)"><div class="check-style-equal-width"></div></div>
                        </div>
                    </div>
                    <div v-else-if="v.id == 350" class="textareaInput">
                    <textarea v-model="inputValue" name="inputValue" id="inputValue" class="input1" cols="20" rows="3" ></textarea>
                    <div class="btn-sure1" @click="showNext(v, idx)"><div class="check-style-equal-width">确定</div></div>
                </div>
                </div>
                <div v-if="_show(1, v, idx)" class="single">
                    <div class="label">{{v.title}}</div>
                    <div v-for="(item,i) in v.options" :key="i" :class="['chioce', singleChioce === i ? 'active' : '']" @click="handleSingle(v, idx, i)">{{item.answer}}</div>
                </div>
                <div v-if="_show(2, v, idx)" class="multi">
                    <div class="label">{{v.title}}</div>
                    <div class="chioce-wrap">
                        <div v-for="(item,i) in v.options" :key="i" :class="['chioce', mulChioce.indexOf(i) > -1 ? 'active' : '']" @click="handleMul(i)">{{item.answer}}</div>
                    </div>
                    <div v-if="mulBtnShow" class="btn-sure" @click="handleMultiNext(v, idx)">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Top from "@/components/top"
import Middle from "@/components/middle"

export default {
    components: {
        Top, Middle
    },
    data() {
        return {
            questionList: [],//测评所有维度完成之后，不会有这个信息
                // questionType 1单选 2多选 3填空
                // blankType input的类型 1数字 2字符串
            tips: "", // 填空题错误提示
            inputValue: "", // input内容
            locationIndex: 0, // 当前在做题目的下标
            moveClass: true, // 是否显示切换的动画
            singleChioce: null, // 单选题选中的下标
            mulChioce: [], // 多选题的下标
            mulBtnShow: false, // 多选按钮是否隐藏
            answers: [], // 提交测评的答案
            answerStr: "", // 答案的字符串集合
            showMiddle: false, // 是否显示中转的特效
            tid: "", // 测评的tid
            dimension: {} , // 答题的维度的信息
            dimensionList: [],
            dimensionNext: null,
            testType: 'try',
            chioceSikpMap: {},//是否跳过当前题目
        }
    },
    mounted() {
        this.testType = sessionStorage.getItem('type') || 'try'
        console.info("mounted testType",this.testType)
        this._initData()
    },
    methods: {
        // 获取初始化数据
        _initData() {
            console.info("res","_initData")
            const tid = localStorage.getItem('tid')
            this.$api.assessmentInfo(this.testType, tid).then(res => {
                console.info("res",res)
                if (+res.data.result.finish === 1) {
                    this.$router.push("/resultDefault")
                }
                if (res.data.dimension.tid) this.tid = res.data.dimension.tid
                // 初始化数据
                this.locationIndex = 0
                this.questionList = res.data.question_list
                this.dimensionList = res.data.dimension.list
                this.dimensionNext = res.data.dimension.next
                this.dimension = res.data.dimension
                this.showMiddle = true

                console.log(this.questionList)
            }).catch()
        },
        // 监听中转页关闭事件
        handleclose(bool) {
            this.showMiddle = !bool
        },
        // 监听返回上一题， e题目的题号不是下标
        handleBack(e) {
            console.log("handleBack",e);
            const arr = this.answers
            console.log("chioceSikpMap",this.chioceSikpMap[e]);
         
            if(this.chioceSikpMap[e]){
                this.locationIndex = this.locationIndex - 1
               e = e - 1
            }
            // 删除当前题目的答案(注意有没有保留记录，保留则删除，没保留则不删除)
            if (arr.length-1 === e-1) {
                arr.splice(e, 1)
            }
            this.answers = arr
            this.locationIndex = e
            this._showNowAnswer(arr, e)
        },
        // 切换填空题
        showNext(v, idx) {
            if (!this.inputValue) {
                this.tips = "请输入正确的值且内容不为空"
                return
            }
            this._record('fill', v, idx)
            this._effect()
            this._reset()
            this._transform()
            this._lastQuestion()
        },
        // 切换单选题
        handleSingle(v, idx, i) {
            console.log("handleSingle",v,idx,i);
            this.singleChioce = i
            if(v.id && v.id == 321 && i == 1){ 
                console.log("选中女性，跳过第4题");
                this.locationIndex = this.locationIndex + 1
                this.chioceSikpMap[this.locationIndex] = true; // 使用方括号表示法设置键值对
                console.log("chioceSikpMap",this.chioceSikpMap);
            }
            this._record('single', v, idx)
            this._effect()
            this._reset()
            this._transform()
            this._lastQuestion()
        },
        // 多选点击
        handleMul(i) {
            const arr = this.mulChioce
            if (arr.indexOf(i) > -1) {
                arr.splice(arr.indexOf(i), 1)
            } else {
                arr.push(i)
            }
            this.mulBtnShow = arr.length > 0 ? true : false
            this.mulChioce = arr
        },
        // 多选确认按钮
        handleMultiNext(v, idx) {
            this.mulBtnShow = false
            this._record('mul', v, idx)

            if(v.id && v.id == 322){ 
                console.log("选中男性，跳过第5题");
                this.locationIndex = this.locationIndex + 1
                this.chioceSikpMap[this.locationIndex] = true; // 使用方括号表示法设置键值对
                console.log("chioceSikpMap",this.chioceSikpMap);
            }
            
            this._effect()
            this._reset()
            this._transform()
            this._lastQuestion()
        },
        // 判断是否是最后一题，自动提交
        _lastQuestion() {
            console.log(this.locationIndex === this.questionList.length -1)
            if (this.locationIndex === this.questionList.length -1) {
                // 提交答案
                this._submit()
                return
            }
        },
        // 提交
        _submit() {
            const orderId = localStorage.getItem('orderId') || ""
            const params = {
                tid: this.tid,
                order_id: orderId,
                dimension: this.dimension.next,
                answer: this.answerStr
            }
            this.$api.submit({data: params}).then(res => {
                console.log(res)
                if (+res.data.result.finish === 1) {
                    window.location.href = res.data.result.redirect_url
                    // this.$router.push(res.data.result.redirect_url)
                } else {
                    // 继续下一个维度
                    this.answers = []
                    this.answerStr = ""
                    this._initData()
                }
            }).catch()
        },
        // 展示当前问题的选择结果
        _showNowAnswer(arr, index) {
            switch (arr[index].questionType) {
                case 1:
                this.singleChioce = arr[index].recordValue
                break;
                case 2:
                this.mulChioce = arr[index].recordValue
                break;
                case 3:
                this.inputValue = arr[index].recordValue
                break;
            }
        },
        // 记录所做的选择
        _record(type, v, idx) {
            const arr = this.answers
            const record = type === 'fill' ? this.inputValue : (type === 'single' ? this.singleChioce : this.mulChioce)
            const paramsObj = {
                index: idx,
                id: v.id,
                questionType: v.questionType,
                recordValue: record
            }
            if (arr[idx]) {
                // 替换原来的值
                arr[idx] = paramsObj
            } else {
                arr.push(paramsObj)
            }
            this.answers = arr
        },
        // 切换题目的动效函数
        _effect() {
            this.moveClass = false
            setTimeout(() => {
                if (this.questionList.length === this.locationIndex) return
                this.locationIndex = ++this.locationIndex
                this.moveClass = true
            }, 100)
        },
        // 判断当前题目类型
        _show(type, v, idx) {
            return type === v.questionType && this.locationIndex === idx
        },
        // 重置数据
        _reset() {
            this.inputValue = ""
            this.singleChioce = null
            this.mulChioce = []
        },
        // 数据转化
        _transform() {
            const arr = []
            console.log(this.answers)
            this.answers.forEach((v, i) => {
                let str = ""
                const item = this.questionList[i]
                if (v.questionType === 1) {
                    str = `${v.id}:${item['options'][v.recordValue].value}:${item['options'][v.recordValue].key}:${item['options'][v.recordValue].answer}`
                } else if (v.questionType === 2) {
                    let mulValueStr = ''
                    let mulKeyStr = ''
                    let mulAnswerStr = ''
                    v.recordValue.forEach((val, index) => {
                        if (v.recordValue.length-1 === index ) {
                            mulValueStr += item['options'][val].value
                            mulKeyStr += item['options'][val].key
                            mulAnswerStr += item['options'][val].answer
                        } else {
                            mulValueStr += item['options'][val].value + "|||"
                            mulKeyStr += item['options'][val].key + "|||"
                            mulAnswerStr += item['options'][val].answer + "|||"
                        }
                    })
                    str = `${v.id}:${mulValueStr}:${mulKeyStr}:${mulAnswerStr}`
                } else if (v.questionType === 3) {
                    str = `${v.id}:0:0:${v.recordValue}`
                }
                arr.push(str)
            })
            this.answerStr = arr.join("##")
            console.log(arr, this.answerStr)
        }
    }
}
</script>

<style scoped lang="less">
.content {

    .label {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        color: #333;
        margin: 29px auto;
        display: block;
    }
    .filling {
        position: relative;
        .tips {
            position: absolute;
            font-size: 11px;
            left: 50%;
            transform: translateX(-50%);
            top: 27px;
            color:#ff0000;
        }
        .fill {
            display: flex;
            justify-content: center;
            align-items: center;
            .fill-input {
                position: relative;
                width: 194px;
                height: 54px;
                border: 2px solid #333;
                border-radius: 27px;
                display: flex;
                justify-content: center;
                align-items: center;
                .input  {
                    width: 189px;
                    color: #333;
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                    border: none;
                    background: transparent;
                    outline: none;
                    margin-right: 7px;
                }
                .right-bg {
                    position: absolute;
                    right: -44px;
                    width: 37px;
                    height: 37px;
                    border-radius: 50%;
                    background: #99F2B2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .check-style-equal-width {
                        width: 8px;
                        height: 16px;
                        border-radius: 2px;
                        border-color: #333;
                        border-style: solid;
                        border-width: 0 4px 4px 0;
                        overflow: hidden;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
    .single {
        display: flex;
        flex-direction: column;
        align-items: center;
        .chioce {
            width: 275px;
            height: 52px;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #333;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 15px;
            background: #fff;
            &.active {
                color: #fff;
                background: #333333;
            }
        }
    }
    .multi {
        padding: 0 40px;
        .chioce-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .chioce {
                flex: 1 1 calc(50% - 10px); /* 调整为每行两个，留出间距 */
                margin-bottom: 15px;
                height: auto; /* 允许高度自适应 */
                border-radius: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #333;
                font-size: 15px;
                font-weight: bold;
                background: #fff;
                box-sizing: border-box;
                padding: 10px; /* 增加 padding 使内容更好适应 */
                text-align: center;
                white-space: normal; /* 允许换行 */
                overflow: hidden;
                text-overflow: ellipsis;
                &.active {
                  color: #fff;
                  background: #333333;
                }
                &:nth-child(2n) {
                  margin-right: 0; /* 处理每行最后一个元素的右边距 */
                }
                &:not(:nth-child(2n)) {
                  margin-right: 10px; /* 为非每行最后一个元素设置右边距 */
                }
              }
        }
        .btn-sure {
            width: 147px;
            height: 52px;
            background: #99F2B2;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            color: #333;
            font-weight: bold;
            margin: 13px auto;
        }
    }
    .btn-sure1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 40px;
        width: 147px;
        height: 52px;
        background: #99F2B2;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #333;
        font-weight: bold;
        margin: 13px auto;
    }
    .textareaInput {
        display: flex;
        flex-direction: column;
    }
    .input1 {
        width: 100%; /* Adjust the width as needed */
        max-width: 400px; /* Adjust the max-width as needed */
        border-radius: 14px;
    }
    
}
</style>
