<template>
    <div class="top-wrap">
        <span class="last" @click="toLast(current)">上一题</span>
        <div class="progress"><div class="progress-in" :style="{'width': width}"></div></div>
        <span class="num">{{total === current ? total: current+1}}/{{total}}</span>
    </div>
</template>

<script>
export default {
    props: {
        current: {
            type: Number,
            default: 1 // 下标+1，当前第几题
        },
        total: {
            type: Number
        }
    },
    data() {
        return {

        }
    }, 
    computed: {
        width() {
            return (this.current / this.total) * 100 + "%"
        }
    },
    mounted() {

    },
    methods: {
        toLast(current) {
            const number = current > 0 ? --current : current
            this.$emit('back', number)
        },
        _width() {
            return (this.current / this.total) * 100 + "%"
        }
    }
}
</script>

<style scoped lang="less">
.top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        color: #333;
        font-size: 12px;
        font-weight: 700;
    }
    .default {
        color: gray;
    }
    .progress {
        width: 262px;
        height: 20px;
        border-radius: 10px;
        background: #fff;
        overflow: hidden;
        .progress-in {
            background: #99F2B2;
            height: 100%;
        }
    }
}
</style>
