<template>
  <div class="home">
    <Question/>
  </div>
</template>

<script>
// @ is an alias to /src

import Question from "@/components/question"
import "animate.css"
export default {
  name: 'Home',
  components: {
    Question
  },
}
</script>
<style lang="less" scoped>
  .home {
    padding: 16.5px 10px;
  }
</style>
